import * as React from "react";
import Box from "@sproutsocial/seeds-react-box";
// eslint-disable-next-line import/no-deprecated
import Text from "@sproutsocial/seeds-react-text";
import type { TypeEmptyStateProps } from "./EmptyStateTypes";

const EmptyState = ({
  media,
  headline,
  subtext,
  primaryAction,
  secondaryAction,
  ...rest
}: TypeEmptyStateProps) => {
  return (
    <Box maxWidth="400px" mx="auto" data-qa-emptystate={headline} {...rest}>
      {media && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mb={450}
        >
          {media}
        </Box>
      )}

      <Text as="div" textAlign="center">
        <Text
          as="p"
          m={0}
          color="text.headline"
          fontWeight="semibold"
          fontSize={400}
        >
          {headline}
        </Text>

        {subtext && (
          <Text as="p" color="text.subtext" fontSize={200} mb={0} mt={400}>
            {subtext}
          </Text>
        )}

        <Box mt={primaryAction || secondaryAction ? 450 : 0}>
          {primaryAction &&
            React.cloneElement(primaryAction, {
              appearance: "primary",
            })}

          {secondaryAction && (
            <Box mt={400}>
              {React.cloneElement(secondaryAction, {
                appearance: "unstyled",
              })}
            </Box>
          )}
        </Box>
      </Text>
    </Box>
  );
};

export default EmptyState;
