import * as React from "react";
import { useState, useRef, useContext, useEffect } from "react";
import { useMeasure } from "@sproutsocial/seeds-react-hooks";
import Box from "@sproutsocial/seeds-react-box";
import { CollapsingBox } from "./styles";
import type { TypeCollapsibleProps } from "./CollapsibleTypes";

let idCounter = 0;

interface TypeCollapsibleContext {
  isOpen?: boolean;
  id?: string;
  offset?: number;
  openHeight?: number;
  collapsedHeight?: number;
}

const CollapsibleContext = React.createContext<TypeCollapsibleContext>({});

const Collapsible = ({
  children,
  isOpen = false,
  offset = 0,
  collapsedHeight = 0,
  openHeight,
}: TypeCollapsibleProps) => {
  const [id] = useState(`Racine-collapsible-${idCounter++}`);
  return (
    <CollapsibleContext.Provider
      value={{
        isOpen,
        id,
        offset,
        collapsedHeight,
        openHeight,
      }}
    >
      {children}
    </CollapsibleContext.Provider>
  );
};

const determineMaxHeight = (
  isHidden?: boolean,
  openHeight?: number,
  computedHeight?: number
): number | undefined => {
  // If isHidden is undefined this is the first render.  Return undefined so the max-height prop is not added
  // This is a hack to prevent css from animating if it begins in the open state
  // css animates when attribute values change (IE from 0 to another number)
  // css does not animate when simply adding an attribute to an HTML element
  if (isHidden === undefined) return undefined;
  // If the user has defined an explicit open height, return that as the max height
  if (openHeight) return openHeight;
  // Otherwise, fallback to the computed height
  return computedHeight;
};

const Trigger = ({ children, ...rest }: { children: React.ReactElement }) => {
  const { isOpen, id } = useContext(CollapsibleContext);
  return (
    <React.Fragment>
      {React.cloneElement(children, {
        "aria-controls": id,
        "aria-expanded": !!isOpen,
        ...rest,
      })}
    </React.Fragment>
  );
};

Trigger.displayName = "Collapsible.Trigger";

const Panel = ({ children, ...rest }: { children: React.ReactNode }) => {
  const {
    isOpen,
    id,
    offset = 0,
    collapsedHeight,
    openHeight,
  } = useContext(CollapsibleContext);

  const ref = useRef<HTMLDivElement | null>(null);
  const measurement = useMeasure(ref);
  const [isHidden, setIsHidden] = useState<boolean | undefined>(undefined);
  const maxHeight = determineMaxHeight(
    isHidden,
    openHeight,
    // Round up to the nearest pixel to prevent subpixel rendering issues
    Math.ceil(measurement.height + offset)
  );

  /* We use the "hidden" attribute to remove the contents of the panel from the tab order of the page, but it interferes with the animation. This logic sets a slight timeout on setting the prop so that the animation has time to complete before the attribute is set. */
  useEffect(() => {
    if (!isOpen) {
      const timeoutID = setTimeout(() => setIsHidden(!isOpen), 300);
      return () => clearTimeout(timeoutID);
    } else {
      // Similar to the close animation, we need to delay setting hidden to run slightly async.
      // An issue occurs with the initial render isHidden logic that causes the animation to occur sporadically.
      // using this 0 second timeout just allows this component to initially render with an undefined max height,
      // Then go directly from undefined to the full max height, without a brief 0 value that triggers an animation
      const timeoutID = setTimeout(() => setIsHidden(!isOpen), 0);
      return () => clearTimeout(timeoutID);
    }
  }, [isOpen]);

  return (
    <CollapsingBox
      hasShadow={Boolean(collapsedHeight || (openHeight && openHeight > 0))}
      scrollable={isOpen}
      maxHeight={isOpen ? maxHeight : collapsedHeight}
      minHeight={collapsedHeight}
      data-qa-collapsible=""
      data-qa-collapsible-isopen={isOpen === true}
      {...rest}
    >
      <Box
        width="100%"
        hidden={isHidden && collapsedHeight === 0}
        aria-hidden={!isOpen}
        id={id}
        ref={ref}
      >
        {children}
      </Box>
    </CollapsingBox>
  );
};

Panel.displayName = "Collapsible.Panel";

Collapsible.Trigger = Trigger;
Collapsible.Panel = Panel;

export default Collapsible;
