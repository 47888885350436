import React, { useState } from "react";
import { useTextContent } from "@sproutsocial/seeds-react-hooks";
import Box from "@sproutsocial/seeds-react-box";
import Label from "@sproutsocial/seeds-react-label";
import Text from "@sproutsocial/seeds-react-text";
import { VisuallyHidden } from "@sproutsocial/seeds-react-visually-hidden";
import type { TypeFormFieldProps } from "./FormFieldTypes";

let idCounter = 0;

const FormField = ({
  children,
  error,
  helperText,
  id: identifier,
  isInvalid = false,
  label,
  mb = 400,
  qa,
  isLabelHidden = false,
  required,
  ...rest
}: TypeFormFieldProps) => {
  const [id] = useState(identifier || `FormField-${idCounter++}`);
  const errorId = `Error-${id}`;
  const containerText = useTextContent("");
  const errorContainerText = useTextContent("");

  return (
    <Box
      {...rest}
      {...qa}
      mb={mb}
      data-qa-formfield={
        (qa && qa["data-qa-formfield"]) || id || containerText.current
      }
      data-qa-formfield-isinvalid={isInvalid === true}
    >
      {isLabelHidden ? (
        <VisuallyHidden data-testid="visually-hidden">
          <Label htmlFor={id} required={required}>
            {label}
          </Label>
        </VisuallyHidden>
      ) : (
        <Label mb={helperText ? 100 : 300} htmlFor={id} required={required}>
          {label}
        </Label>
      )}
      {helperText && (
        <Text as="p" fontSize={200} mb={300} color="text.subtext">
          {helperText}
        </Text>
      )}
      {children({
        id,
        isInvalid,
        ariaDescribedby: errorId,
        ...(required !== undefined && { required }),
      })}
      {isInvalid && error && (
        <Text
          as="div"
          fontSize={200}
          color="text.error"
          mt={300}
          id={errorId}
          data-qa-formfield-error={
            (qa && qa["data-qa-formfield-error"]) || errorContainerText.current
          }
        >
          {error}
        </Text>
      )}
    </Box>
  );
};

export default FormField;
