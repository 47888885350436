import * as React from "react";
import ImageContainer from "./styles";
import type { TypeImageProps } from "./ImageTypes";

type TypeState = {
  didError: boolean;
  didLoad: boolean;
  imageUrl: string;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export default class Image extends React.Component<TypeImageProps, TypeState> {
  static defaultProps = {
    title: "",
    onError: noop,
    onClick: noop,
    onLoad: noop,
  };

  override state = {
    didError: false,
    didLoad: false,
    imageUrl: this.props.src,
  };

  imageRef?: HTMLImageElement | null;

  override componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    if (this.imageRef) {
      this.setState({
        didLoad: this.imageRef.complete,
      });
    }
  }

  override componentDidUpdate(prevProps: TypeImageProps) {
    if (this.props.src !== prevProps.src) {
      this.setState({
        didError: false,
        didLoad: false,
        imageUrl: this.props.src,
      });
    }
  }

  onError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (!this.state.didError && this.props.defaultImage) {
      this.setState({
        didError: true,
        imageUrl: this.props.defaultImage,
      });
    }

    this.props.onError(e);
  };

  onLoad = () => {
    this.setState({
      didLoad: true,
    });
    this.props.onLoad();
  };

  onClick = () => {
    this.props.onClick();
  };

  override render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { alt, title, onClick, onError, onLoad, src, qa, ...rest } =
      this.props;
    return (
      <ImageContainer
        isLoading={!this.state.didLoad}
        ref={(img) => (this.imageRef = img)}
        onClick={this.onClick}
        src={this.state.imageUrl}
        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
          this.onError(e)
        }
        onLoad={this.onLoad}
        alt={alt}
        title={title}
        data-qa-image={title ? title : alt ? alt : ""}
        data-qa-image-src={src || ""}
        {...(qa as any)}
        {...rest}
      />
    );
  }
}
