import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";

export interface CharacterCounterContainer {
  overlimit: boolean;
  mini: boolean;
}

const Container = styled.div<CharacterCounterContainer>`
  font-family: ${(props) => props.theme.fontFamily};
  ${(props) => props.theme.typography[200]};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  line-height: 1;
  font-variant-numeric: tabular-nums;
  color: ${(props) =>
    props.overlimit
      ? props.theme.colors.text.error
      : props.theme.colors.text.subtext};

  ${(p) =>
    p.mini &&
    css`
      ${p.theme.typography[100]}
    `}

  ${COMMON}
`;

export default Container;
