import styled from "styled-components";
import { visuallyHidden } from "@sproutsocial/seeds-react-mixins";
import type { HTMLAttributes } from "react";
import React from "react";

export interface VisuallyHiddenProps extends HTMLAttributes<HTMLSpanElement> {}

const StyledVisuallyHidden = styled.span<VisuallyHiddenProps>`
  ${visuallyHidden}
`;

export const VisuallyHidden: React.FC<VisuallyHiddenProps> = (props) => {
  return <StyledVisuallyHidden {...props} />;
};
