function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/* eslint-disable no-restricted-imports */
import { transparentize } from "polished";
// json-to-scss is not working with the alias right now
//import {theme as baseLightTheme, type TypeSproutTheme} from '@src';
import baseLightTheme from "../../../light/theme";
export var navigation = {
  main: {
    background: {
      base: baseLightTheme.colors.neutral[900],
      overflowGradient: baseLightTheme.colors.neutral[1000]
    },
    border: {
      base: baseLightTheme.colors.neutral[1000]
    }
  },
  secondary: {
    background: {
      base: baseLightTheme.colors.neutral[800]
    },
    widget: {
      background: {
        base: baseLightTheme.colors.neutral[1000]
      }
    },
    accordion: {
      background: {
        base: baseLightTheme.colors.neutral[900]
      }
    }
  },
  settings: {
    listItem: {
      background: {
        base: "transparent",
        hover: baseLightTheme.colors.neutral[200],
        selected: baseLightTheme.colors.neutral[0]
      }
    }
  },
  text: {
    base: baseLightTheme.colors.neutral[0],
    hover: baseLightTheme.colors.neutral[0]
  },
  icon: {
    base: baseLightTheme.colors.neutral[0],
    hover: baseLightTheme.colors.neutral[0]
  },
  listItem: {
    background: {
      base: baseLightTheme.colors.neutral[800],
      hover: baseLightTheme.colors.neutral[1000],
      selected: baseLightTheme.colors.neutral[700]
    }
  }
};
export var datePicker = {
  comparison: {
    background: {
      base: baseLightTheme.colors.neutral[400]
    },
    text: {
      base: baseLightTheme.colors.neutral[800]
    }
  }
};
export var ai = {
  feature: {
    decorative: {
      // these two colors form a gradient in AI features
      primary: baseLightTheme.colors.teal[800],
      secondary: baseLightTheme.colors.blue[900]
    },
    background: {
      primary: baseLightTheme.colors.teal[800],
      secondary: baseLightTheme.colors.blue[900],
      default: {
        primary: baseLightTheme.colors.teal[800],
        secondary: baseLightTheme.colors.blue[900]
      },
      inverse: {
        primary: baseLightTheme.colors.teal[100],
        secondary: baseLightTheme.colors.blue[200]
      }
    }
  }
};
export var analytics = {
  trend: {
    positive: baseLightTheme.colors.teal[800],
    neutral: baseLightTheme.colors.neutral[800],
    negative: baseLightTheme.colors.neutral[800]
  },
  overlay: {
    background: {
      base: transparentize(0.2, baseLightTheme.colors.neutral[0])
    }
  }
};
export var listening = {
  chart: {
    indicator: {
      default: {
        primary: baseLightTheme.colors.neutral[400],
        secondary: baseLightTheme.colors.neutral[0]
      },
      hover: {
        primary: baseLightTheme.colors.neutral[1000],
        secondary: baseLightTheme.colors.neutral[0]
      }
    },
    spike: {
      background: {
        base: baseLightTheme.colors.teal[500]
      },
      icon: {
        base: baseLightTheme.colors.neutral[0]
      }
    }
  },
  topicTypes: {
    customTopic: baseLightTheme.colors.blue[400],
    brandHealth: baseLightTheme.colors.red[400],
    industryInsights: baseLightTheme.colors.green[400],
    competitiveAnalysis: baseLightTheme.colors.yellow[400],
    campaignAnalysis: baseLightTheme.colors.magenta[400],
    eventMonitoring: baseLightTheme.colors.aqua[400],
    featuredTopic: baseLightTheme.colors.green[400]
  },
  worldMap: {
    empty: baseLightTheme.colors.neutral[200],
    q0: baseLightTheme.colors.purple[200],
    q1: baseLightTheme.colors.purple[300],
    q2: baseLightTheme.colors.purple[400],
    q3: baseLightTheme.colors.purple[500],
    q4: baseLightTheme.colors.purple[600],
    q5: baseLightTheme.colors.purple[700],
    q6: baseLightTheme.colors.purple[800],
    q7: baseLightTheme.colors.purple[900]
  }
};
export var growth = {
  carousel: {
    indicator: {
      active: baseLightTheme.colors.blue[600],
      inactive: baseLightTheme.colors.neutral[300]
    }
  },
  education: {
    decorative: {
      aqua: baseLightTheme.colors.aqua[600],
      teal: baseLightTheme.colors.teal[700]
    }
  },
  opportunity: {
    background: {
      base: baseLightTheme.colors.purple[700],
      secondary: baseLightTheme.colors.neutral[900],
      hover: baseLightTheme.colors.purple[300]
    },
    button: {
      primary: {
        base: baseLightTheme.colors.purple[700],
        hover: baseLightTheme.colors.purple[800]
      }
    },
    badge: {
      background: {
        base: baseLightTheme.colors.purple[200],
        active: baseLightTheme.colors.purple[800]
      },
      icon: {
        base: baseLightTheme.colors.purple[700],
        active: baseLightTheme.colors.purple[100]
      },
      text: {
        base: baseLightTheme.colors.purple[700]
      }
    },
    decorative: {
      // confetti
      green: baseLightTheme.colors.green[700],
      lightAqua: baseLightTheme.colors.aqua[600],
      darkAqua: baseLightTheme.colors.aqua[1100],
      purple: baseLightTheme.colors.purple[700]
    }
  },
  featuredDemo: {
    background: {
      primary: {
        base: baseLightTheme.colors.blue[700],
        hover: baseLightTheme.colors.blue[900]
      },
      secondary: {
        base: baseLightTheme.colors.purple[800],
        hover: baseLightTheme.colors.purple[800]
      }
    }
  },
  darkModal: {
    background: {
      base: baseLightTheme.colors.aqua[1100]
    },
    text: {
      base: baseLightTheme.colors.neutral[0]
    },
    cards: {
      background: {
        base: baseLightTheme.colors.neutral[0],
        hover: baseLightTheme.colors.purple[100]
      },
      text: {
        base: baseLightTheme.colors.neutral[800],
        hover: baseLightTheme.colors.neutral[900]
      },
      border: {
        base: baseLightTheme.colors.neutral[0],
        hover: baseLightTheme.colors.purple[700]
      }
    }
  },
  user: {
    status: {
      online: baseLightTheme.colors.green[700]
    }
  }
};
export var cardControl = {
  background: {
    base: baseLightTheme.colors.neutral[0],
    selected: baseLightTheme.colors.neutral[800],
    hover: baseLightTheme.colors.neutral[100]
  },
  text: {
    selected: baseLightTheme.colors.text.inverse
  }
};
var lightTheme = _objectSpread(_objectSpread({}, baseLightTheme), {}, {
  colors: _objectSpread(_objectSpread({}, baseLightTheme.colors), {}, {
    ai: ai,
    navigation: navigation,
    datePicker: datePicker,
    analytics: analytics,
    listening: listening,
    growth: growth,
    cardControl: cardControl
  })
});
export default lightTheme;