import COLORS from "@sproutsocial/seeds-color";
export var green = {
  background: COLORS.COLOR_GREEN_900,
  highlight: COLORS.COLOR_GREEN_500,
  foreground: COLORS.COLOR_GREEN_100
};
export var blue = {
  background: COLORS.COLOR_BLUE_900,
  highlight: COLORS.COLOR_BLUE_600,
  foreground: COLORS.COLOR_BLUE_100
};
export var purple = {
  background: COLORS.COLOR_PURPLE_900,
  highlight: COLORS.COLOR_PURPLE_500,
  foreground: COLORS.COLOR_PURPLE_100
};
export var yellow = {
  background: COLORS.COLOR_YELLOW_900,
  highlight: COLORS.COLOR_YELLOW_600,
  foreground: COLORS.COLOR_YELLOW_100
};
export var orange = {
  background: COLORS.COLOR_ORANGE_900,
  highlight: COLORS.COLOR_ORANGE_600,
  foreground: COLORS.COLOR_ORANGE_100
};
export var red = {
  background: COLORS.COLOR_RED_900,
  highlight: COLORS.COLOR_RED_600,
  foreground: COLORS.COLOR_RED_100
};
export var neutral = {
  background: COLORS.COLOR_NEUTRAL_1000,
  highlight: COLORS.COLOR_NEUTRAL_600,
  foreground: COLORS.COLOR_NEUTRAL_100
};
export var magenta = {
  background: COLORS.COLOR_MAGENTA_900,
  highlight: COLORS.COLOR_MAGENTA_600,
  foreground: COLORS.COLOR_MAGENTA_100
};
export var pink = {
  background: COLORS.COLOR_PINK_900,
  highlight: COLORS.COLOR_PINK_600,
  foreground: COLORS.COLOR_PINK_100
};
export var aqua = {
  background: COLORS.COLOR_AQUA_900,
  highlight: COLORS.COLOR_AQUA_500,
  foreground: COLORS.COLOR_AQUA_100
};
export var teal = {
  background: COLORS.COLOR_TEAL_900,
  highlight: COLORS.COLOR_TEAL_500,
  foreground: COLORS.COLOR_TEAL_100
};