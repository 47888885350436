import * as React from "react";
import Container from "./styles";
import type { TypeCharacterCounterProps } from "./CharacterCounterTypes";

export const CharacterCounter = ({
  currentValue,
  maxValue,
  size = "default",
  ...rest
}: TypeCharacterCounterProps) => {
  const remaining = maxValue - currentValue;
  return (
    <Container
      role="status"
      mini={size === "mini"}
      overlimit={remaining < 0}
      {...rest}
    >
      {remaining}
    </Container>
  );
};
