import * as React from "react";
import Text from "@sproutsocial/seeds-react-text";
import Container from "./styles";
import type { TypeKeyboardKeyProps } from "./KeyboardKeyTypes";

export const KeyboardKey = ({ children, ...rest }: TypeKeyboardKeyProps) => {
  return (
    <Container {...rest}>
      <kbd data-qa-keyboardkey={children}>
        <Text>{children}</Text>
      </kbd>
    </Container>
  );
};
