import * as React from "react";
import styled from "styled-components";
import Text from "@sproutsocial/seeds-react-text";
import type { TypeLabelProps } from "./LabelTypes";

const LabelBase = ({ as = "label", ...rest }: TypeLabelProps) => (
  <Text {...rest} as={as} />
);

// TODO: Screen readers should read "required" instead of "*"
const StyledLabel = styled(LabelBase)`
  display: flex;
  align-items: center;
`;

const Label = ({ htmlFor, required, children, ...props }: TypeLabelProps) => {
  return (
    <StyledLabel
      fontSize={200}
      fontWeight="semibold"
      color="text.headline"
      data-qa-label={htmlFor}
      htmlFor={htmlFor}
      {...props}
    >
      {children}
      {required && (
        <Text ml={100} aria-hidden>
          *
        </Text>
      )}
    </StyledLabel>
  );
};

export default Label;
