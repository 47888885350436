function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/* eslint-disable no-restricted-imports */
import { transparentize } from "polished";
import COLORS from "@sproutsocial/seeds-color";
import NETWORKCOLORS from "@sproutsocial/seeds-networkcolor";
// eslint-disable-next-line no-restricted-syntax
import TYPOGRAPHY from "@sproutsocial/seeds-typography";
import SPACE from "@sproutsocial/seeds-space";
import DEPTH from "@sproutsocial/seeds-depth";
import MOTION from "@sproutsocial/seeds-motion";
import BORDER from "@sproutsocial/seeds-border";
// json-to-scss is not working with the alias right now
//import {TypeThemeMode} from '@src/types/theme';

import literalColors from "./literal-colors";
import { datavizPalette } from "./dataviz-palette";
import { green, blue, purple, yellow, orange, red, neutral, magenta, pink, aqua, teal } from "./decorative-palettes";
export var breakpoints = ["900px", "1200px", "1500px", "1800px"];
var MODE = "light";

// If you are making changes to the colors in the theme file tag the Design Systems team on your PR! Thank you!!

var colors = _objectSpread(_objectSpread({
  app: {
    background: {
      base: COLORS.COLOR_NEUTRAL_100
    }
  },
  container: {
    background: {
      base: COLORS.COLOR_NEUTRAL_0,
      success: green.background,
      warning: yellow.background,
      error: red.background,
      info: blue.background,
      opportunity: purple.background,
      /**  @deprecated Use "error" instead of "danger" */
      danger: red.background,
      decorative: {
        green: green.background,
        blue: blue.background,
        purple: purple.background,
        yellow: yellow.background,
        orange: orange.background,
        red: red.background,
        neutral: neutral.background,
        magenta: magenta.background,
        pink: pink.background,
        aqua: aqua.background,
        teal: teal.background
      },
      selected: COLORS.COLOR_NEUTRAL_800,
      positive_sentiment: COLORS.COLOR_BLUE_500,
      negative_sentiment: COLORS.COLOR_RED_500,
      neutral_sentiment: COLORS.COLOR_NEUTRAL_300
    },
    border: {
      base: COLORS.COLOR_NEUTRAL_200,
      success: green.highlight,
      warning: yellow.highlight,
      error: red.highlight,
      /**  @deprecated Use "error" instead of "danger" */
      danger: red.highlight,
      info: blue.highlight,
      opportunity: purple.highlight,
      decorative: {
        green: green.highlight,
        blue: blue.highlight,
        purple: purple.highlight,
        yellow: yellow.highlight,
        orange: orange.highlight,
        red: red.highlight,
        neutral: neutral.highlight,
        magenta: magenta.highlight,
        pink: pink.highlight,
        aqua: aqua.highlight,
        teal: teal.highlight
      },
      selected: COLORS.COLOR_NEUTRAL_800
    }
  },
  button: {
    primary: {
      background: {
        base: COLORS.COLOR_BLUE_700,
        hover: COLORS.COLOR_BLUE_800,
        active: COLORS.COLOR_BLUE_900
      },
      border: {
        base: "transparent"
      },
      text: {
        base: COLORS.COLOR_NEUTRAL_0,
        hover: COLORS.COLOR_NEUTRAL_0
      }
    },
    secondary: {
      background: {
        base: "transparent",
        hover: COLORS.COLOR_NEUTRAL_800,
        active: COLORS.COLOR_NEUTRAL_900
      },
      border: {
        base: COLORS.COLOR_NEUTRAL_800
      },
      text: {
        base: COLORS.COLOR_NEUTRAL_800,
        hover: COLORS.COLOR_NEUTRAL_0
      }
    },
    pill: {
      background: {
        base: "transparent",
        hover: COLORS.COLOR_NEUTRAL_100,
        active: COLORS.COLOR_NEUTRAL_200
      },
      border: {
        base: "transparent",
        hover: COLORS.COLOR_NEUTRAL_800
      },
      text: {
        base: COLORS.COLOR_NEUTRAL_800,
        hover: COLORS.COLOR_NEUTRAL_900
      }
    },
    destructive: {
      background: {
        base: COLORS.COLOR_RED_800,
        hover: COLORS.COLOR_RED_900,
        active: COLORS.COLOR_RED_1000
      },
      border: {
        base: "transparent"
      },
      text: {
        base: COLORS.COLOR_NEUTRAL_0,
        hover: COLORS.COLOR_NEUTRAL_0
      }
    },
    placeholder: {
      background: {
        base: "transparent",
        hover: COLORS.COLOR_NEUTRAL_0,
        active: COLORS.COLOR_NEUTRAL_0
      },
      border: {
        base: COLORS.COLOR_NEUTRAL_500
      },
      text: {
        base: COLORS.COLOR_BLUE_700,
        hover: COLORS.COLOR_BLUE_800
      }
    },
    unstyled: {
      background: {
        base: "transparent"
      },
      border: {
        base: "transparent"
      },
      text: {
        base: COLORS.COLOR_NEUTRAL_700,
        hover: COLORS.COLOR_NEUTRAL_900
      }
    }
  },
  link: {
    base: COLORS.COLOR_BLUE_800,
    hover: COLORS.COLOR_BLUE_900
  },
  text: {
    headline: COLORS.COLOR_NEUTRAL_900,
    subtext: COLORS.COLOR_NEUTRAL_700,
    body: COLORS.COLOR_NEUTRAL_800,
    inverse: COLORS.COLOR_NEUTRAL_0,
    error: red.foreground,
    background: {
      highlight: COLORS.COLOR_YELLOW_200,
      selection: COLORS.COLOR_BLUE_300
    },
    decorative: {
      green: green.foreground,
      blue: blue.foreground,
      purple: purple.foreground,
      yellow: yellow.foreground,
      orange: orange.foreground,
      red: red.foreground,
      neutral: neutral.foreground,
      magenta: magenta.foreground,
      pink: pink.foreground,
      aqua: aqua.foreground,
      teal: teal.foreground
    }
  },
  icon: {
    base: COLORS.COLOR_NEUTRAL_800,
    inverse: COLORS.COLOR_NEUTRAL_0,
    success: green.foreground,
    warning: yellow.foreground,
    error: red.foreground,
    /**  @deprecated Use "error" instead of "danger" */
    danger: red.foreground,
    info: blue.foreground,
    opportunity: purple.foreground,
    applied: COLORS.COLOR_BLUE_700,
    positive_sentiment: COLORS.COLOR_BLUE_600,
    negative_sentiment: COLORS.COLOR_RED_600,
    neutral_sentiment: COLORS.COLOR_NEUTRAL_500
  },
  form: {
    background: {
      base: COLORS.COLOR_NEUTRAL_0,
      selected: COLORS.COLOR_NEUTRAL_800
    },
    border: {
      base: COLORS.COLOR_NEUTRAL_600,
      error: red.highlight,
      warning: yellow.highlight,
      selected: COLORS.COLOR_NEUTRAL_800
    },
    placeholder: {
      base: COLORS.COLOR_NEUTRAL_700
    }
  },
  listItem: {
    background: {
      base: "transparent",
      hover: COLORS.COLOR_NEUTRAL_100,
      selected: COLORS.COLOR_NEUTRAL_800,
      active: COLORS.COLOR_NEUTRAL_400
    },
    border: {
      base: neutral.highlight
    }
  },
  overlay: {
    background: {
      base: transparentize(0.44, COLORS.COLOR_NEUTRAL_1000)
    },
    text: {
      base: COLORS.COLOR_NEUTRAL_0
    },
    icon: {
      base: COLORS.COLOR_NEUTRAL_0
    }
  },
  elevation: {
    base: "".concat(COLORS.COLOR_NEUTRAL_900, "3D")
  },
  illustration: {
    fill: COLORS.COLOR_BLUE_400,
    stroke: neutral.foreground
  },
  network: {
    twitter: NETWORKCOLORS.NETWORK_COLOR_TWITTER,
    twitter_like: NETWORKCOLORS.NETWORK_COLOR_TWITTER_LIKE,
    facebook: NETWORKCOLORS.NETWORK_COLOR_FACEBOOK,
    facebook_audience_network: NETWORKCOLORS.NETWORK_COLOR_FACEBOOK_AUDIENCE_NETWORK,
    linkedin: NETWORKCOLORS.NETWORK_COLOR_LINKEDIN,
    instagram: NETWORKCOLORS.NETWORK_COLOR_INSTAGRAM,
    feedly: NETWORKCOLORS.NETWORK_COLOR_FEEDLY,
    analytics: NETWORKCOLORS.NETWORK_COLOR_ANALYTICS,
    youtube: NETWORKCOLORS.NETWORK_COLOR_YOUTUBE,
    messenger: NETWORKCOLORS.NETWORK_COLOR_MESSENGER,
    snapchat: NETWORKCOLORS.NETWORK_COLOR_SNAPCHAT,
    pinterest: NETWORKCOLORS.NETWORK_COLOR_PINTEREST,
    tumblr: NETWORKCOLORS.NETWORK_COLOR_TUMBLR,
    reddit: NETWORKCOLORS.NETWORK_COLOR_REDDIT,
    tripadvisor: NETWORKCOLORS.NETWORK_COLOR_TRIPADVISOR,
    glassdoor: NETWORKCOLORS.NETWORK_COLOR_GLASSDOOR,
    google_my_business: NETWORKCOLORS.NETWORK_COLOR_GOOGLE_MY_BUSINESS,
    google_business_messages: NETWORKCOLORS.NETWORK_COLOR_GOOGLE_BUSINESS_MESSAGES,
    google_play_store: NETWORKCOLORS.NETWORK_COLOR_GOOGLE_PLAY_STORE,
    apple_app_store: NETWORKCOLORS.NETWORK_COLOR_APPLE_APP_STORE,
    salesforce: NETWORKCOLORS.NETWORK_COLOR_SALESFORCE,
    zendesk: NETWORKCOLORS.NETWORK_COLOR_ZENDESK,
    hubspot: NETWORKCOLORS.NETWORK_COLOR_HUBSPOT,
    microsoft_dynamics: NETWORKCOLORS.NETWORK_COLOR_MICROSOFT_DYNAMICS,
    yelp: NETWORKCOLORS.NETWORK_COLOR_YELP,
    whatsapp: NETWORKCOLORS.NETWORK_COLOR_WHATSAPP,
    tiktok: NETWORKCOLORS.NETWORK_COLOR_TIKTOK,
    threads: NETWORKCOLORS.NETWORK_COLOR_THREADS,
    trustpilot: NETWORKCOLORS.NETWORK_COLOR_TRUSTPILOT,
    x: NETWORKCOLORS.NETWORK_COLOR_X,
    x_like: NETWORKCOLORS.NETWORK_COLOR_X_LIKE,
    bluesky: NETWORKCOLORS.NETWORK_COLOR_BLUESKY
  },
  dataviz: {
    map: datavizPalette.DATAVIZ_COLORS_MAP,
    list: datavizPalette.DATAVIZ_COLORS_LIST,
    placeholder: datavizPalette.PLACEHOLDER
  },
  userStatus: {
    online: COLORS.COLOR_GREEN_700,
    offline: COLORS.COLOR_RED_600,
    unavailable: COLORS.COLOR_NEUTRAL_600
  }
}, literalColors), datavizPalette);
export var typography = {
  100: TYPOGRAPHY.TYPOGRAPHY_SIZE_100,
  200: TYPOGRAPHY.TYPOGRAPHY_SIZE_200,
  300: TYPOGRAPHY.TYPOGRAPHY_SIZE_300,
  400: TYPOGRAPHY.TYPOGRAPHY_SIZE_400,
  500: TYPOGRAPHY.TYPOGRAPHY_SIZE_500,
  600: TYPOGRAPHY.TYPOGRAPHY_SIZE_600,
  700: TYPOGRAPHY.TYPOGRAPHY_SIZE_700,
  800: TYPOGRAPHY.TYPOGRAPHY_SIZE_800,
  900: TYPOGRAPHY.TYPOGRAPHY_SIZE_900,
  1000: TYPOGRAPHY.TYPOGRAPHY_SIZE_1000,
  1100: TYPOGRAPHY.TYPOGRAPHY_SIZE_1100,
  1200: TYPOGRAPHY.TYPOGRAPHY_SIZE_1200
};
export var fontSizes = {
  100: TYPOGRAPHY.TYPOGRAPHY_SIZE_100.fontSize,
  200: TYPOGRAPHY.TYPOGRAPHY_SIZE_200.fontSize,
  300: TYPOGRAPHY.TYPOGRAPHY_SIZE_300.fontSize,
  400: TYPOGRAPHY.TYPOGRAPHY_SIZE_400.fontSize,
  500: TYPOGRAPHY.TYPOGRAPHY_SIZE_500.fontSize,
  600: TYPOGRAPHY.TYPOGRAPHY_SIZE_600.fontSize,
  700: TYPOGRAPHY.TYPOGRAPHY_SIZE_700.fontSize,
  800: TYPOGRAPHY.TYPOGRAPHY_SIZE_800.fontSize,
  900: TYPOGRAPHY.TYPOGRAPHY_SIZE_900.fontSize,
  1000: TYPOGRAPHY.TYPOGRAPHY_SIZE_1000.fontSize,
  1100: TYPOGRAPHY.TYPOGRAPHY_SIZE_1100.fontSize,
  1200: TYPOGRAPHY.TYPOGRAPHY_SIZE_1200.fontSize
};
export var lineHeights = {
  100: TYPOGRAPHY.TYPOGRAPHY_SIZE_100.lineHeight,
  200: TYPOGRAPHY.TYPOGRAPHY_SIZE_200.lineHeight,
  300: TYPOGRAPHY.TYPOGRAPHY_SIZE_300.lineHeight,
  400: TYPOGRAPHY.TYPOGRAPHY_SIZE_400.lineHeight,
  500: TYPOGRAPHY.TYPOGRAPHY_SIZE_500.lineHeight,
  600: TYPOGRAPHY.TYPOGRAPHY_SIZE_600.lineHeight,
  700: TYPOGRAPHY.TYPOGRAPHY_SIZE_700.lineHeight,
  800: TYPOGRAPHY.TYPOGRAPHY_SIZE_800.lineHeight,
  900: TYPOGRAPHY.TYPOGRAPHY_SIZE_900.lineHeight,
  1000: TYPOGRAPHY.TYPOGRAPHY_SIZE_1000.lineHeight,
  1100: TYPOGRAPHY.TYPOGRAPHY_SIZE_1100.lineHeight,
  1200: TYPOGRAPHY.TYPOGRAPHY_SIZE_1200.lineHeight
};
export var fontFamily = TYPOGRAPHY.TYPOGRAPHY_FAMILY;
export var fontWeights = {
  normal: TYPOGRAPHY.TYPOGRAPHY_WEIGHT_NORMAL,
  semibold: TYPOGRAPHY.TYPOGRAPHY_WEIGHT_SEMIBOLD,
  bold: TYPOGRAPHY.TYPOGRAPHY_WEIGHT_BOLD,
  extrabold: TYPOGRAPHY.TYPOGRAPHY_WEIGHT_EXTRA_BOLD
};
export var space = {
  0: SPACE.SPACE_SIZE_0,
  100: SPACE.SPACE_SIZE_100,
  200: SPACE.SPACE_SIZE_200,
  300: SPACE.SPACE_SIZE_300,
  350: SPACE.SPACE_SIZE_350,
  400: SPACE.SPACE_SIZE_400,
  450: SPACE.SPACE_SIZE_450,
  500: SPACE.SPACE_SIZE_500,
  600: SPACE.SPACE_SIZE_600
};
export var radii = {
  400: BORDER.BORDER_RADIUS_400,
  500: BORDER.BORDER_RADIUS_500,
  600: BORDER.BORDER_RADIUS_600,
  900: BORDER.BORDER_RADIUS_900,
  1000: BORDER.BORDER_RADIUS_1000,
  inner: BORDER.BORDER_RADIUS_500,
  outer: BORDER.BORDER_RADIUS_600,
  pill: BORDER.BORDER_RADIUS_1000
};
export var borders = {
  500: "".concat(BORDER.BORDER_WIDTH_500, " solid"),
  600: "".concat(BORDER.BORDER_WIDTH_600, " solid"),
  700: "".concat(BORDER.BORDER_WIDTH_700, " solid")
};
export var borderWidths = {
  500: BORDER.BORDER_WIDTH_500,
  600: BORDER.BORDER_WIDTH_600,
  700: BORDER.BORDER_WIDTH_700
};
export var shadows = {
  low: "".concat(DEPTH.ELEVATION_LEVEL_100, " ").concat(COLORS.COLOR_NEUTRAL_900, "3D"),
  medium: "".concat(DEPTH.ELEVATION_LEVEL_300, " ").concat(COLORS.COLOR_NEUTRAL_900, "3D"),
  high: "".concat(DEPTH.ELEVATION_LEVEL_400, " ").concat(COLORS.COLOR_NEUTRAL_900, "3D")
};
export var easing = {
  ease_in: MOTION.MOTION_EASE_IN,
  ease_out: MOTION.MOTION_EASE_OUT,
  ease_inout: MOTION.MOTION_EASE_INOUT
};
export var duration = {
  fast: MOTION.MOTION_DURATION_FAST,
  medium: MOTION.MOTION_DURATION_MEDIUM,
  slow: MOTION.MOTION_DURATION_SLOW
};
var theme = {
  breakpoints: breakpoints,
  colors: colors,
  typography: _objectSpread(_objectSpread({}, typography), {}, {
    typography: typography
  }),
  fontSizes: fontSizes,
  lineHeights: lineHeights,
  fontFamily: fontFamily,
  fontWeights: fontWeights,
  space: _objectSpread(_objectSpread({}, space), {}, {
    space: space,
    "-space": Object.keys(space).reduce(function (negativeSpace, key) {
      // The values are strings (eg 24px) so we concatenate a negative sign
      // @ts-ignore: no explicit any
      negativeSpace[key] = "-".concat(space[key]);
      return negativeSpace;
    }, {})
  }),
  radii: _objectSpread(_objectSpread({}, radii), {}, {
    radii: radii
  }),
  borders: borders,
  borderWidths: borderWidths,
  shadows: shadows,
  easing: easing,
  duration: duration,
  mode: MODE
};
export default theme;