import * as React from "react";
import Container from "./styles";
import type { TypeChartLegendProps } from "./ChartLegendTypes";
import { useChartLabels } from "./useChartLabels";

const ChartLegend = ({
  legendLabels,
  stacked,
  theme = "datavizRotation",
  qa,
  ...rest
}: TypeChartLegendProps) => {
  const chartLabels = useChartLabels(legendLabels, theme);

  return (
    <Container inline={!stacked} data-qa-chartlegend="" qa={qa} {...rest}>
      {chartLabels}
    </Container>
  );
};

export default ChartLegend;
