import * as React from "react";
import Box from "@sproutsocial/seeds-react-box";
import Text from "@sproutsocial/seeds-react-text";
import { ChildContainer } from "./styles";
import type {
  TypeFieldsetProps,
  TypeFieldsetLegendProps,
  TypeFieldsetHelperText,
} from "./FieldsetTypes";

/**
 * Fieldset Component
 */
const Fieldset = ({
  layout = "vertical",
  label,
  helperText,
  children,
  ...rest
}: TypeFieldsetProps) => (
  <Box as="fieldset" border="none" p={0} data-qa-fieldset="" {...rest}>
    {typeof label === "string" ? (
      <Fieldset.Legend>{label}</Fieldset.Legend>
    ) : (
      label
    )}
    {typeof helperText === "string" ? (
      <Fieldset.HelperText>{helperText}</Fieldset.HelperText>
    ) : (
      helperText
    )}
    <Box
      display="flex"
      flexDirection={layout === "vertical" ? "column" : "row"}
    >
      {React.Children.map(children, (child) => (
        <ChildContainer layout={layout}>{child}</ChildContainer>
      ))}
    </Box>
  </Box>
);

const FieldsetLegend = ({ children, ...rest }: TypeFieldsetLegendProps) => (
  <Text
    as="legend"
    fontSize={300}
    fontWeight="semibold"
    color="text.body"
    mb={300}
    {...rest}
  >
    {children}
  </Text>
);

const FieldsetHelperText = ({ children, ...rest }: TypeFieldsetHelperText) => (
  <Text as="p" fontSize={200} mb={300} {...rest}>
    {children}
  </Text>
);

FieldsetLegend.displayName = "Fieldset.Legend";
FieldsetHelperText.displayName = "Fieldset.HelperText";

Fieldset.Legend = FieldsetLegend;
Fieldset.HelperText = FieldsetHelperText;

export default Fieldset;
