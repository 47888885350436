import * as React from "react";
import { useContext, useEffect, useRef } from "react";
import FocusLock from "react-focus-lock";
import { animated, useTransition } from "@react-spring/web";
import { MOTION_DURATION_MEDIUM } from "@sproutsocial/seeds-motion/unitless";
import Box from "@sproutsocial/seeds-react-box";
import Button from "@sproutsocial/seeds-react-button";
import Icon from "@sproutsocial/seeds-react-icon";
// eslint-disable-next-line import/no-deprecated
import Text from "@sproutsocial/seeds-react-text";
import Portal from "@sproutsocial/seeds-react-portal";
import Container, { Content } from "./styles";
import type {
  TypeDrawerContext,
  TypeDrawerCloseButtonProps,
  TypeDrawerHeaderProps,
  TypeDrawerProps,
  TypeInnerDrawerProps,
  TypeDrawerContentProps,
  TypeUseCloseOnBodyClickProps,
} from "./DrawerTypes";

const useSlideTransition = ({
  isVisible,
  width,
  direction,
}: {
  isVisible: boolean;
  width: number;
  direction: "left" | "right";
}) => {
  const offset = width * (direction === "left" ? -1 : 1);

  return useTransition(isVisible, {
    from: {
      opacity: 0,
      x: offset,
    },
    enter: {
      opacity: 1,
      x: 0,
    },
    leave: {
      opacity: 0,
      x: offset,
    },
    config: {
      duration: MOTION_DURATION_MEDIUM * 1000,
    },
  });
};

const AnimatedDrawer = animated(Container);

const doesRefContainEventTarget = (
  ref: { current: { contains: (arg0: any) => any } },
  event: Event
) => {
  return (
    ref.current &&
    event.target instanceof Node &&
    ref.current.contains(event.target)
  );
};

const DrawerContext = React.createContext<TypeDrawerContext>({});

const DrawerCloseButton = (props: TypeDrawerCloseButtonProps) => {
  const { onClose, closeButtonLabel } = useContext(DrawerContext);

  if (props.render) {
    return (
      props.render({
        onClose,
        closeButtonLabel,
      }) ?? null
    );
  }

  return (
    <Button
      appearance="pill"
      aria-label={closeButtonLabel}
      onClick={onClose}
      {...props}
    >
      {props.children || <Icon aria-hidden name="x-outline" />}
    </Button>
  );
};

const DrawerHeader = ({
  title = "",
  id = undefined,
  children,
  render,
  ...rest
}: TypeDrawerHeaderProps) => {
  const drawerContext = useContext(DrawerContext);

  if (render) {
    return render(drawerContext);
  }

  return (
    <Box
      display="flex"
      flex="0 0 auto"
      justifyContent="space-between"
      alignItems="center"
      pt={400}
      px={450}
      {...rest}
    >
      {children || (
        <React.Fragment>
          <Text
            as="h2"
            fontSize={400}
            fontWeight="semibold"
            color="text.headline"
            id={id}
          >
            {title}
          </Text>
          <DrawerCloseButton />
        </React.Fragment>
      )}
    </Box>
  );
};

const DrawerContent = ({ children, ...rest }: TypeDrawerContentProps) => (
  <Content height="100%" p={450} color="text.body" {...rest}>
    {children}
  </Content>
);

const useCloseOnBodyClick = ({
  ref,
  disableCloseOnClickOutside,
  onClose,
  closeTargets,
}: TypeUseCloseOnBodyClickProps) => {
  useEffect(() => {
    const documentBody = document.body;

    if (!documentBody) {
      return;
    }

    const onEsc = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    const bodyClick = (event: Event): void => {
      if (
        // @ts-ignore I'm not sure how to type this ref properly
        !doesRefContainEventTarget(ref, event) &&
        !disableCloseOnClickOutside
      ) {
        onClose();
      }
    };

    documentBody?.addEventListener("keydown", onEsc, { capture: true });

    if (closeTargets) {
      closeTargets.forEach((targetElement) =>
        targetElement?.addEventListener("click", bodyClick, { capture: true })
      );
    } else {
      documentBody.firstElementChild?.addEventListener("click", bodyClick, {
        capture: true,
      });
    }

    return () => {
      documentBody?.removeEventListener("keydown", onEsc, { capture: true });

      if (closeTargets) {
        closeTargets.forEach((targetElement) =>
          targetElement?.removeEventListener("click", bodyClick, {
            capture: true,
          })
        );
      } else {
        documentBody.firstElementChild?.removeEventListener(
          "click",
          bodyClick,
          { capture: true }
        );
      }
    };
  }, [onClose, disableCloseOnClickOutside, closeTargets, ref]);
};

const Drawer = ({
  id,
  offset,
  direction,
  children,
  disableCloseOnClickOutside,
  onClose,
  zIndex,
  closeTargets,
  width,
  focusLockExemptCheck,
  isOpen,
  ...rest
}: TypeInnerDrawerProps) => {
  const ref = useRef(null);
  useCloseOnBodyClick({
    ref,
    disableCloseOnClickOutside,
    onClose,
    closeTargets,
  });

  const transition = useSlideTransition({
    isVisible: isOpen,
    width,
    direction,
  });

  return (
    <FocusLock
      key={id}
      autoFocus={true}
      returnFocus
      whiteList={
        focusLockExemptCheck ? (e) => !focusLockExemptCheck(e) : undefined
      }
    >
      {transition((style, isVisible) =>
        isVisible ? (
          <AnimatedDrawer
            ref={ref}
            style={{ ...style, zIndex }}
            width={width}
            offset={offset}
            direction={direction}
            data-qa-drawer={id}
            role="dialog"
            {...rest}
          >
            {children}
          </AnimatedDrawer>
        ) : null
      )}
    </FocusLock>
  );
};

const DrawerContainer = ({
  children,
  closeButtonLabel,
  direction = "right",
  disableCloseOnClickOutside = false,
  id,
  isOpen,
  offset = 0,
  onClose,
  zIndex = 7,
  closeTargets = [],
  width = 600,
  ...rest
}: TypeDrawerProps) => {
  return (
    <Portal id={id}>
      <DrawerContext.Provider
        value={{
          onClose,
          closeButtonLabel,
        }}
      >
        <Drawer
          isOpen={isOpen}
          id={id}
          offset={offset}
          direction={direction}
          disableCloseOnClickOutside={disableCloseOnClickOutside}
          onClose={onClose}
          zIndex={zIndex}
          closeTargets={closeTargets}
          width={width}
          data-qa-drawer={id || ""}
          data-qa-drawer-isopen={isOpen}
          {...rest}
        >
          {children}
        </Drawer>
      </DrawerContext.Provider>
    </Portal>
  );
};

DrawerHeader.displayName = "Drawer.Header";
DrawerContent.displayName = "Drawer.Content";
DrawerCloseButton.displayName = "Drawer.CloseButton";

DrawerContainer.Header = DrawerHeader;
DrawerContainer.Content = DrawerContent;
DrawerContainer.CloseButton = DrawerCloseButton;

export default DrawerContainer;
