import * as React from "react";
import Container from "./styles";
import type { TypeLinkProps } from "./LinkTypes";

const Link = ({
  href,
  external,
  children,
  disabled,
  onClick,
  as,
  underline = true,
  qa = {},
  ...rest
}: TypeLinkProps) => {
  if (!href && external) {
    // eslint-disable-next-line no-console
    console.warn(
      "Warning: external prop cannot be set without a href declaration"
    );
  }

  const type = as || (href ? "a" : "button");
  return (
    <Container
      href={href}
      target={external ? "_blank" : undefined}
      type={type === "button" ? "button" : undefined}
      rel={external ? "noopener noreferrer" : undefined}
      forwardedAs={type}
      aria-disabled={disabled ? disabled : undefined}
      disabled={disabled}
      onClick={onClick}
      underline={underline}
      data-qa-link=""
      data-qa-link-isdisabled={disabled === true}
      {...qa}
      {...rest}
    >
      {children}
    </Container>
  );
};

export default Link;
