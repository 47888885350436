import * as React from "react";
import Container, { Text } from "./styles";
import type { TypeLoaderProps } from "./LoaderTypes";

const Loader = ({
  size = "large",
  text = "",
  delay = true,
  color = "dark",
  qa,
  ...rest
}: TypeLoaderProps) => {
  return (
    <Container
      small={size === "small"}
      dark={color === "dark"}
      delay={delay}
      data-qa-loader=""
      {...qa}
      {...rest}
    >
      <Text>{text || "Loading"}</Text>
    </Container>
  );
};

export default Loader;
