import React from "react";
import styled from "styled-components";
import type { TypeSpotIllustrationProps } from "./SpotIllustrationTypes";
import { ViewBoxes } from "@sproutsocial/seeds-illustrations";

type TypeStyledSVG = Pick<TypeSpotIllustrationProps, "customFill">;

const StyledSVG = styled.svg<TypeStyledSVG>`
  fill: ${(p) =>
    p.customFill
      ? p.theme.colors[p.customFill][400]
      : p.theme.colors.illustration.fill};
  color: ${(p) => p.theme.colors.illustration.stroke};
`;

const SpotIllustration = ({
  name,
  customFill,
  height,
  width,
  ...svgProps
}: TypeSpotIllustrationProps) => {
  const viewBox = ViewBoxes[name];
  const viewBoxCoordinates = viewBox?.split(" ") || [];
  const defaultWidth = viewBoxCoordinates[2];
  const defaultHeight = viewBoxCoordinates[3];
  return (
    <StyledSVG
      role="img"
      width={width ? width : defaultWidth}
      height={height ? height : defaultHeight}
      viewBox={viewBox}
      focusable={false}
      customFill={customFill}
      {...svgProps}
    >
      <use
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xlinkHref={`#seeds-svgs_${name}`}
      />
    </StyledSVG>
  );
};

export default SpotIllustration;
