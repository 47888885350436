function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/* eslint-disable no-restricted-imports */
import { transparentize } from "polished";
// json-to-scss is not working with the alias right now
//import {darkTheme as baseDarkTheme, type TypeSproutTheme} from '@src';
import baseDarkTheme from "../../../dark/theme";
export var navigation = {
  main: {
    background: {
      base: baseDarkTheme.colors.neutral[1000],
      overflowGradient: baseDarkTheme.colors.neutral[1100]
    },
    border: {
      base: baseDarkTheme.colors.neutral[1100]
    }
  },
  secondary: {
    background: {
      base: baseDarkTheme.colors.neutral[900]
    },
    widget: {
      background: {
        base: baseDarkTheme.colors.neutral[800]
      }
    },
    accordion: {
      background: {
        // Uses hexicimal to change the opacity of the color
        // Adding 'A3' at end to make this color have a 64% opacity
        // Reference https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
        base: "".concat(baseDarkTheme.colors.neutral[1000], "A3")
      }
    }
  },
  settings: {
    listItem: {
      background: {
        base: "transparent",
        hover: baseDarkTheme.colors.neutral[1100],
        selected: baseDarkTheme.colors.neutral[800]
      }
    }
  },
  text: {
    base: baseDarkTheme.colors.neutral[0],
    hover: baseDarkTheme.colors.neutral[0]
  },
  icon: {
    base: baseDarkTheme.colors.neutral[0],
    hover: baseDarkTheme.colors.neutral[0]
  },
  listItem: {
    background: {
      base: baseDarkTheme.colors.neutral[1000],
      hover: baseDarkTheme.colors.neutral[1100],
      selected: baseDarkTheme.colors.neutral[700]
    }
  }
};
export var datePicker = {
  comparison: {
    background: {
      base: baseDarkTheme.colors.neutral[400]
    },
    text: {
      base: baseDarkTheme.colors.neutral[800]
    }
  }
};
export var ai = {
  feature: {
    decorative: {
      // identical to light theme
      primary: baseDarkTheme.colors.teal[800],
      secondary: baseDarkTheme.colors.blue[900]
    },
    background: {
      primary: baseDarkTheme.colors.teal[100],
      secondary: baseDarkTheme.colors.blue[200],
      default: {
        primary: baseDarkTheme.colors.teal[100],
        secondary: baseDarkTheme.colors.blue[200]
      },
      inverse: {
        primary: baseDarkTheme.colors.teal[800],
        secondary: baseDarkTheme.colors.blue[900]
      }
    }
  }
};
export var analytics = {
  trend: {
    positive: baseDarkTheme.colors.teal[500],
    neutral: baseDarkTheme.colors.neutral[100],
    negative: baseDarkTheme.colors.neutral[100]
  },
  overlay: {
    background: {
      base: transparentize(0.2, baseDarkTheme.colors.neutral[900])
    }
  }
};
export var listening = {
  chart: {
    indicator: {
      default: {
        primary: baseDarkTheme.colors.neutral[100],
        secondary: baseDarkTheme.colors.neutral[900]
      },
      hover: {
        primary: baseDarkTheme.colors.neutral[300],
        secondary: baseDarkTheme.colors.neutral[0]
      }
    },
    spike: {
      background: {
        base: baseDarkTheme.colors.teal[500]
      },
      icon: {
        base: baseDarkTheme.colors.neutral[0]
      }
    }
  },
  topicTypes: {
    customTopic: baseDarkTheme.colors.teal[400],
    brandHealth: baseDarkTheme.colors.red[400],
    industryInsights: baseDarkTheme.colors.green[400],
    competitiveAnalysis: baseDarkTheme.colors.yellow[400],
    campaignAnalysis: baseDarkTheme.colors.magenta[400],
    eventMonitoring: baseDarkTheme.colors.aqua[400],
    featuredTopic: baseDarkTheme.colors.green[400]
  },
  worldMap: {
    empty: baseDarkTheme.colors.neutral[200],
    q0: baseDarkTheme.colors.purple[200],
    q1: baseDarkTheme.colors.purple[300],
    q2: baseDarkTheme.colors.purple[400],
    q3: baseDarkTheme.colors.purple[500],
    q4: baseDarkTheme.colors.purple[600],
    q5: baseDarkTheme.colors.purple[700],
    q6: baseDarkTheme.colors.purple[800],
    q7: baseDarkTheme.colors.purple[900]
  }
};
export var growth = {
  carousel: {
    indicator: {
      active: baseDarkTheme.colors.blue[600],
      inactive: baseDarkTheme.colors.neutral[300]
    }
  },
  education: {
    decorative: {
      aqua: baseDarkTheme.colors.aqua[600],
      teal: baseDarkTheme.colors.teal[500]
    }
  },
  opportunity: {
    background: {
      base: baseDarkTheme.colors.purple[700],
      secondary: baseDarkTheme.colors.neutral[700],
      hover: baseDarkTheme.colors.purple[500]
    },
    button: {
      primary: {
        base: baseDarkTheme.colors.purple[400],
        hover: baseDarkTheme.colors.purple[300]
      }
    },
    badge: {
      background: {
        base: baseDarkTheme.colors.purple[700],
        active: baseDarkTheme.colors.purple[100]
      },
      icon: {
        base: baseDarkTheme.colors.purple[200],
        active: baseDarkTheme.colors.purple[800]
      },
      text: {
        base: baseDarkTheme.colors.purple[200]
      }
    },
    decorative: {
      // confetti
      green: baseDarkTheme.colors.green[700],
      lightAqua: baseDarkTheme.colors.aqua[600],
      darkAqua: baseDarkTheme.colors.aqua[1100],
      purple: baseDarkTheme.colors.purple[700]
    }
  },
  featuredDemo: {
    background: {
      primary: {
        base: baseDarkTheme.colors.blue[400],
        hover: baseDarkTheme.colors.blue[100]
      },
      secondary: {
        base: baseDarkTheme.colors.purple[500],
        hover: baseDarkTheme.colors.purple[100]
      }
    }
  },
  darkModal: {
    background: {
      base: baseDarkTheme.colors.aqua[1100]
    },
    text: {
      base: baseDarkTheme.colors.neutral[0]
    },
    cards: {
      background: {
        base: baseDarkTheme.colors.neutral[0],
        hover: baseDarkTheme.colors.purple[100]
      },
      text: {
        base: baseDarkTheme.colors.neutral[800],
        hover: baseDarkTheme.colors.neutral[900]
      },
      border: {
        base: baseDarkTheme.colors.neutral[0],
        hover: baseDarkTheme.colors.purple[700]
      }
    }
  },
  user: {
    status: {
      online: baseDarkTheme.colors.green[700]
    }
  }
};
export var cardControl = {
  background: {
    base: baseDarkTheme.colors.neutral[900],
    selected: baseDarkTheme.colors.neutral[700],
    hover: baseDarkTheme.colors.neutral[800]
  },
  text: {
    selected: baseDarkTheme.colors.text.body
  }
};
var darkTheme = _objectSpread(_objectSpread({}, baseDarkTheme), {}, {
  colors: _objectSpread(_objectSpread({}, baseDarkTheme.colors), {}, {
    navigation: navigation,
    datePicker: datePicker,
    ai: ai,
    analytics: analytics,
    listening: listening,
    growth: growth,
    cardControl: cardControl
  })
});
export default darkTheme;