import * as React from "react";
import Box from "@sproutsocial/seeds-react-box";
import Button from "@sproutsocial/seeds-react-button";
import Icon from "@sproutsocial/seeds-react-icon";
import Container, { StyledBannerIcon } from "./styles";
import type { TypeBannerProps, EnumBannerType } from "./BannerTypes";

const getBannerIconName = (type: EnumBannerType) => {
  switch (type) {
    case "success":
      return "circle-check-outline";
    case "error":
      return "triangle-exclamation-outline";
    case "warning":
      return "triangle-exclamation-outline";
    case "opportunity":
      return "sparkle-outline";
    case "info":
    default:
      return "circle-i-outline";
  }
};

const getBannerIconColor = (type: EnumBannerType) => {
  switch (type) {
    case "success":
      return "icon.success";
    case "error":
      return "icon.error";
    case "warning":
      return "icon.warning";
    case "opportunity":
      return "icon.opportunity";
    case "info":
    default:
      return "icon.info";
  }
};

/** TODO: change to 'info' type by default in future version */
const Banner = ({
  type = "info",
  text,
  onClose,
  closeButtonLabel = "Close",
  typeIconProps,
  ...rest
}: TypeBannerProps) => {
  return (
    <Container
      type={type}
      data-qa-alert=""
      data-qa-alert-type={type}
      data-qa-alert-text={text}
      {...rest}
    >
      <Box display="flex" alignItems="center" width={1}>
        <StyledBannerIcon
          name={getBannerIconName(type)}
          color={getBannerIconColor(type)}
          className="Icon"
          fixedWidth
          mt={100}
          mr={350}
          {...typeIconProps}
        />
        {text}
        {onClose && (
          <Box ml="auto" pl={400} alignSelf="flex-start">
            <Button p={0} onClick={onClose} ariaLabel={closeButtonLabel}>
              <Icon name="x-outline" aria-hidden />
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Banner;
