import COLORS from "@sproutsocial/seeds-color";
export var datavizPalette = {
  DATAVIZ_COLORS_LIST: [COLORS.COLOR_TEAL_400, COLORS.COLOR_PURPLE_400, COLORS.COLOR_PINK_400, COLORS.COLOR_YELLOW_500, COLORS.COLOR_BLUE_400, COLORS.COLOR_MAGENTA_400, COLORS.COLOR_GREEN_400, COLORS.COLOR_ORANGE_400, COLORS.COLOR_RED_700, COLORS.COLOR_TEAL_700, COLORS.COLOR_PURPLE_700, COLORS.COLOR_PINK_700, COLORS.COLOR_YELLOW_800, COLORS.COLOR_BLUE_700, COLORS.COLOR_MAGENTA_700, COLORS.COLOR_GREEN_700, COLORS.COLOR_ORANGE_700, COLORS.COLOR_RED_400, COLORS.COLOR_GREEN_200, COLORS.COLOR_YELLOW_200],
  DATAVIZ_COLORS_MAP: {
    "1": COLORS.COLOR_TEAL_400,
    "2": COLORS.COLOR_PURPLE_400,
    "3": COLORS.COLOR_PINK_400,
    "4": COLORS.COLOR_YELLOW_500,
    "5": COLORS.COLOR_BLUE_400,
    "6": COLORS.COLOR_MAGENTA_400,
    "7": COLORS.COLOR_GREEN_400,
    "8": COLORS.COLOR_ORANGE_400,
    "9": COLORS.COLOR_RED_700,
    "10": COLORS.COLOR_TEAL_700,
    "11": COLORS.COLOR_PURPLE_700,
    "12": COLORS.COLOR_PINK_700,
    "13": COLORS.COLOR_YELLOW_800,
    "14": COLORS.COLOR_BLUE_700,
    "15": COLORS.COLOR_MAGENTA_700,
    "16": COLORS.COLOR_GREEN_700,
    "17": COLORS.COLOR_ORANGE_700,
    "18": COLORS.COLOR_RED_400,
    "19": COLORS.COLOR_GREEN_200,
    "20": COLORS.COLOR_YELLOW_200
  },
  PLACEHOLDER: COLORS.COLOR_NEUTRAL_800
};