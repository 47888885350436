import React, { useRef, useState } from "react";
import { StyledCard } from "./styles";
import type { TypeCardProps, TypeCardContext } from "./CardTypes";
import { SubComponentContext, onKeyDown } from "./utils";
import { SelectedIcon } from "./subComponents";

/**
 * @link https://seeds.sproutsocial.com/components/card/
 *
 * Avoid nesting interactive content inside a Card with role='button'.
 *
 * Interactive content: "a", "audio", "button", "embed", "iframe", "img", "input", "label", "select", "textarea", "video"
 * @see https://html.spec.whatwg.org/multipage/dom.html#interactive-content
 *
 * @example
 * <Card role="button" onClick={_onClick}>
 *  <Button>Click me</Button>
 * </Card>
 */

const Card = ({
  children,
  disabled = false,
  elevation,
  href,
  onClick,
  role = "presentation",
  selected,
  ...rest
}: TypeCardProps) => {
  const [hasSubComponent, setHasSubComponent] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const isRoleLink = role === "link";
  const checkedConditions = role === "checkbox" ? selected : undefined;

  const cardContext: TypeCardContext = {
    setHasSubComponent: setHasSubComponent,
    href: href,
    linkRef: linkRef,
  };

  const handleClickConditions: React.MouseEventHandler = (e) =>
    isRoleLink ? linkRef.current?.click() : onClick?.(e);

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) =>
    onKeyDown({ e, href, onClick, ref: containerRef, role });

  return (
    <StyledCard
      tabIndex={isRoleLink ? -1 : 0}
      role={isRoleLink ? undefined : role}
      onClick={handleClickConditions}
      onKeyDown={handleKeyDown}
      $elevation={elevation}
      ref={containerRef}
      $selected={selected}
      aria-checked={checkedConditions}
      $disabled={disabled}
      aria-disabled={disabled && disabled}
      $compositionalComponents={hasSubComponent}
      $isRoleLink={isRoleLink}
      {...rest}
    >
      <SelectedIcon $selected={selected} />
      <SubComponentContext.Provider value={cardContext}>
        {children}
      </SubComponentContext.Provider>
    </StyledCard>
  );
};

export default Card;
