import React, { useContext } from "react";
import { useChildContext, SubComponentContext } from "./utils";
import type {
  TypeCardLink,
  TypeSharedCardSystemProps,
  TypeStyledSelectedIcon,
} from "./CardTypes";
import {
  StyledCardContent,
  StyledCardHeader,
  StyledCardFooter,
  StyledSelectedIcon,
  SelectedIconWrapper,
  StyledCardAffordance,
  StyledCardLink,
} from "./styles";

interface TypeSharedSubComponentProps extends TypeSharedCardSystemProps {
  children?: React.ReactNode;
}

export const CardContent = ({
  children,
  ...rest
}: TypeSharedSubComponentProps) => {
  // TODO: It could be cool to possibly adjust the context to include an array of names of child components.
  // Then, if CardHeader or CardFooter aren't used with CardContent throw an error.
  useChildContext();
  return <StyledCardContent {...rest}>{children}</StyledCardContent>;
};

export const CardHeader = ({
  children,
  ...rest
}: TypeSharedSubComponentProps) => {
  useChildContext();
  return <StyledCardHeader {...rest}>{children}</StyledCardHeader>;
};

export const CardFooter = ({
  children,
  ...rest
}: TypeSharedSubComponentProps) => {
  useChildContext();
  return <StyledCardFooter {...rest}>{children}</StyledCardFooter>;
};

interface TypeSelectedIconProps {
  $selected?: TypeStyledSelectedIcon["$selected"];
}

export const SelectedIcon = ({ $selected }: TypeSelectedIconProps) => {
  return (
    <SelectedIconWrapper>
      <StyledSelectedIcon
        aria-hidden
        color="icon.base"
        name="circle-check-solid"
        $selected={$selected}
      />
    </SelectedIconWrapper>
  );
};

export const CardAffordance = ({ ...rest }) => {
  return (
    <StyledCardAffordance
      {...rest}
      size="mini"
      name="arrow-right-solid"
      // TODO: probably need to make this available to the top level for external links https://sprout.atlassian.net/browse/DS-2223
      aria-hidden
    />
  );
};

export const CardLink = ({
  affordance,
  children,
  external = false,
  color,
  ...rest
}: React.PropsWithChildren<TypeCardLink>) => {
  const { href, linkRef } = useContext(SubComponentContext);

  // Because we are hijacking Card click event to directly click this link, we need to stop propagation to avoid a double click event.
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  };

  return (
    <StyledCardLink
      {...rest}
      target={external ? "_blank" : undefined}
      rel={external ? "noreferrer" : undefined}
      href={href}
      onClick={handleClick}
      ref={linkRef}
      // TODO: fix this type since `color` should be valid here. TS can't resolve the correct type.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      color={color}
    >
      <>
        {children}
        {affordance ? <CardAffordance ml={300} /> : null}
      </>
    </StyledCardLink>
  );
};
