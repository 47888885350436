import { useState, useCallback, useMemo, memo, useEffect } from "react";
import styled, { css } from "styled-components";

import Box from "@sproutsocial/seeds-react-box";
import Image from "@sproutsocial/seeds-react-image";
import Text from "@sproutsocial/seeds-react-text";
import type {
  TypeAvatarProps,
  TypeAvatarContainerProps,
  TypeAvatarType,
} from "./AvatarTypes";

import { BORDER } from "@sproutsocial/seeds-react-system-props";

const defaultType = "neutral";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AvatarText = styled(({ fontSize, ...rest }) => <Text {...rest} />)`
  font-size: ${(props) => props.fontSize}px;
  color: ${({ theme, type, color }) =>
    color ? color : theme.colors.text.decorative[type]};
`;

const Container = styled(Box)<TypeAvatarContainerProps>`
  ${({ theme, $type, bg, borderColor, $displayFallback }) => css`
    background: ${$displayFallback
      ? bg
        ? bg
        : theme.colors.container.background.decorative[$type]
      : "none"};
    border: ${$displayFallback ? `1px solid` : "none"};
    border-color: ${borderColor
      ? borderColor
      : theme.colors.container.border.decorative[$type]};
    color: ${theme.colors.text.decorative[$type]};
  `}

  ${BORDER}
`;

const getInitials = (name: string, fallback = "?"): string => {
  if (!name || typeof name !== "string") return fallback;
  return name
    .replace(/\s+/, " ")
    .split(" ") // Repeated spaces results in empty strings
    .slice(0, 2)
    .map((v) => v && v[0]?.toUpperCase()) // Watch out for empty strings
    .join("");
};

export const getAvatarColor = (
  name: string,
  type: TypeAvatarType
): TypeAvatarType => {
  if (type !== "auto") {
    return type;
  }

  const colors: Array<TypeAvatarType> = [
    "purple",
    "green",
    "blue",
    "yellow",
    "red",
    "orange",
  ];

  // Condense the avatar name down into a number
  const seed = name.split("").reduce((seed, char) => {
    return seed + char.charCodeAt(0);
  }, 0);

  // Use that seed modulo the number of available colors to generate
  // a "random" color value which will always be consistent
  // for a given string. As a failsafe, return neutral (this should never happen).
  return colors[seed % colors.length] || "neutral";
};

export const Avatar = ({
  appearance = "circle",
  name = "",
  src,
  type = defaultType,
  size = "40px",
  bg,
  color,
  initials,
  ...rest
}: TypeAvatarProps) => {
  const colorType = getAvatarColor(name, type);
  const [imageFailedLoading, setImageFailedLoading] = useState(false);

  useEffect(() => {
    // If the src changes, we need to invalidate the image failed to load flag
    setImageFailedLoading(false);
  }, [src]);

  const displayInitials = useMemo(
    () => initials || getInitials(name),
    [initials, name]
  );
  const handleError = useCallback(() => {
    setImageFailedLoading(true);
  }, [setImageFailedLoading]);

  // Font size for initials is half the size of the avatar, rounded down.
  const fontSize = Math.floor(Number(size.replace("px", "")) * 0.4);

  return (
    <Container
      size={size}
      overflow="hidden"
      borderRadius={appearance === "leaf" ? "40% 0 40% 0" : "50%"}
      position="relative"
      display="flex"
      flexShrink={0}
      justifyContent="center"
      alignItems="center"
      title={name}
      bg={bg}
      $type={colorType}
      data-qa-user-avatar={name}
      $displayFallback={!src || imageFailedLoading}
      {...rest}
    >
      {!src || imageFailedLoading ? (
        <AvatarText
          lineHeight={size}
          fontWeight="semibold"
          fontSize={fontSize}
          type={colorType}
          color={color}
        >
          {displayInitials}
        </AvatarText>
      ) : (
        <Image
          alt={name}
          width="auto"
          height="100%"
          src={src}
          onError={handleError}
          m={0}
        />
      )}
    </Container>
  );
};
export default memo(Avatar);
