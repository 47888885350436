import type {
  TypeDurationDisplayUnits,
  TypeDurationMilliseconds,
} from "./DurationTypes";
import { MILLISECONDS_IN, ORDERED_UNITS, UNITS } from "./constants";

export const getLowestUnit = (displayUnits: TypeDurationDisplayUnits) =>
  [...ORDERED_UNITS]
    .reverse()
    .find((unit) => displayUnits[unit as keyof TypeDurationDisplayUnits]) ||
  UNITS.milliseconds;

export const splitMillisecondsIntoUnits = (
  milliseconds: number,
  displayUnits: TypeDurationDisplayUnits
): {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
} => {
  const lowestUnit = getLowestUnit(displayUnits);

  // @ts-ignore TS error later
  const remainder = milliseconds % MILLISECONDS_IN[lowestUnit];
  // @ts-ignore TS error later
  if (2 * remainder >= MILLISECONDS_IN[lowestUnit]) {
    // if the remainder is large, add enough seconds to increse the lowest unit
    // @ts-ignore TS error later
    milliseconds += MILLISECONDS_IN[lowestUnit] - remainder;
  }

  const units = {};

  ORDERED_UNITS.forEach((unit) => {
    // @ts-ignore TS error later
    if (displayUnits[unit]) {
      // @ts-ignore TS error later
      units[unit] = Math.floor(milliseconds / MILLISECONDS_IN[unit]);
      // @ts-ignore TS error later
      milliseconds -= units[unit] * MILLISECONDS_IN[unit];
    }
  });

  return units;
};

export const isValidNumber = (value: unknown): boolean =>
  typeof value === "number" && isFinite(value);

export interface TypeGetDurationMaxDisplayUnitsProps {
  milliseconds: TypeDurationMilliseconds;
  maxDisplayUnits: number;
}

export const getDurationMaxDisplayUnits = ({
  milliseconds,
  maxDisplayUnits = ORDERED_UNITS.length,
}: TypeGetDurationMaxDisplayUnitsProps): TypeDurationDisplayUnits => {
  const displayUnits = {};

  if (!isValidNumber(milliseconds)) {
    return displayUnits;
  }

  for (const unit of ORDERED_UNITS) {
    if (Object.keys(displayUnits).length >= maxDisplayUnits) {
      break;
    }

    // @ts-expect-error - stupid typescript isn't smart enough to check the isValidNumber check above ¯\_(ツ)_/¯
    const millisecondsByUnit = splitMillisecondsIntoUnits(milliseconds, {
      days: true,
      hours: true,
      minutes: true,
      seconds: true,
      milliseconds: true,
    });

    // @ts-expect-error - stupid typescript isn't smart enough to check the isValidNumber check above ¯\_(ツ)_/¯
    if (milliseconds > MILLISECONDS_IN[unit] && millisecondsByUnit[unit] > 0) {
      // @ts-ignore TS error later
      displayUnits[unit] = true;
    }
  }

  return displayUnits;
};
