import * as React from "react";
import Box from "@sproutsocial/seeds-react-box";
import Icon from "@sproutsocial/seeds-react-icon";
import Container from "./styles";
import type { TypeBadgeProps } from "./BadgeTypes";

const Badge = ({
  children,
  text,
  iconName,
  type,
  tip,
  size = "small",
  badgeColor = "blue",
  color,
  ...rest
}: TypeBadgeProps) => {
  if (children && text) {
    throw new Error(
      "can't use both `children` and `text` props. Text is deprecated, consider using children."
    );
  }

  return (
    <Container
      // size previously included default, which currently maps to small.  Once consumers have updated this can be simplified.
      size={size === "default" ? "large" : size}
      badgeColor={badgeColor}
      data-tip={tip}
      data-qa-badge={text || ""}
      data-qa-badge-type={type}
      data-qa-badge-tip={tip || ""}
      type={type}
      // TODO: fix this type since `color` should be valid here. TS can't resolve the correct type.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      color={color}
      {...rest}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        {iconName ? (
          <Icon
            mr={200}
            name={iconName}
            size={size === "small" ? "mini" : "small"}
            aria-hidden
          />
        ) : null}
        {children || text}
      </Box>
    </Container>
  );
};

export default Badge;
