import COLORS from "@sproutsocial/seeds-color";
export var datavizPalette = {
  DATAVIZ_COLORS_LIST: [COLORS.COLOR_TEAL_500, COLORS.COLOR_PURPLE_700, COLORS.COLOR_PINK_700, COLORS.COLOR_YELLOW_500, COLORS.COLOR_BLUE_500, COLORS.COLOR_MAGENTA_500, COLORS.COLOR_GREEN_500, COLORS.COLOR_ORANGE_500, COLORS.COLOR_RED_700, COLORS.COLOR_TEAL_900, COLORS.COLOR_PURPLE_400, COLORS.COLOR_PINK_900, COLORS.COLOR_YELLOW_900, COLORS.COLOR_BLUE_900, COLORS.COLOR_MAGENTA_900, COLORS.COLOR_GREEN_900, COLORS.COLOR_ORANGE_900, COLORS.COLOR_RED_400, COLORS.COLOR_GREEN_700, COLORS.COLOR_YELLOW_800],
  DATAVIZ_COLORS_MAP: {
    "1": COLORS.COLOR_TEAL_500,
    "2": COLORS.COLOR_PURPLE_700,
    "3": COLORS.COLOR_PINK_700,
    "4": COLORS.COLOR_YELLOW_500,
    "5": COLORS.COLOR_BLUE_500,
    "6": COLORS.COLOR_MAGENTA_500,
    "7": COLORS.COLOR_GREEN_500,
    "8": COLORS.COLOR_ORANGE_500,
    "9": COLORS.COLOR_RED_700,
    "10": COLORS.COLOR_TEAL_900,
    "11": COLORS.COLOR_PURPLE_400,
    "12": COLORS.COLOR_PINK_900,
    "13": COLORS.COLOR_YELLOW_900,
    "14": COLORS.COLOR_BLUE_900,
    "15": COLORS.COLOR_MAGENTA_900,
    "16": COLORS.COLOR_GREEN_900,
    "17": COLORS.COLOR_ORANGE_900,
    "18": COLORS.COLOR_RED_400,
    "19": COLORS.COLOR_GREEN_700,
    "20": COLORS.COLOR_YELLOW_800
  },
  PLACEHOLDER: COLORS.COLOR_NEUTRAL_300
};