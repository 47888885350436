import React from "react";
import type {
  TypeChartLegendTheme,
  TypeLegendLabels,
} from "./ChartLegendTypes";
import type { TypeColor } from "@sproutsocial/seeds-react-theme";
import { useTheme } from "styled-components";
import { Label, Swatch } from "./styles";
import { Text } from "@sproutsocial/seeds-react-text";

const getSwatchColor = (
  theme: TypeChartLegendTheme,
  index: number,
  RacineThemeColors: TypeColor
) => {
  const COMPARE_THEME = [
    RacineThemeColors.dataviz.map["1"],
    RacineThemeColors.dataviz.map["10"],
    RacineThemeColors.dataviz.map["11"],
    RacineThemeColors.dataviz.map["2"],
  ];

  const CONTRAST_THEME = [
    RacineThemeColors.dataviz.map["1"],
    RacineThemeColors.dataviz.map["2"],
    RacineThemeColors.dataviz.map["3"],
    RacineThemeColors.dataviz.map["4"],
    RacineThemeColors.dataviz.map["5"],
    RacineThemeColors.dataviz.map["6"],
    RacineThemeColors.dataviz.map["4"],
    RacineThemeColors.dataviz.map["5"],
    RacineThemeColors.dataviz.map["6"],
    RacineThemeColors.dataviz.map["7"],
    RacineThemeColors.dataviz.map["8"],
    RacineThemeColors.dataviz.map["9"],
    RacineThemeColors.dataviz.map["10"],
  ];

  const EXTENDED_THEME = [
    RacineThemeColors.dataviz.map["7"],
    RacineThemeColors.dataviz.map["1"],
    RacineThemeColors.dataviz.map["5"],
    RacineThemeColors.dataviz.map["11"],
    RacineThemeColors.dataviz.map["6"],
    RacineThemeColors.dataviz.map["3"],
    RacineThemeColors.dataviz.map["18"],
    RacineThemeColors.dataviz.map["8"],
    RacineThemeColors.dataviz.map["4"],
    RacineThemeColors.dataviz.map["13"],
    RacineThemeColors.dataviz.map["16"],
    RacineThemeColors.dataviz.map["10"],
    RacineThemeColors.dataviz.map["14"],
    RacineThemeColors.dataviz.map["2"],
    RacineThemeColors.dataviz.map["15"],
    RacineThemeColors.dataviz.map["12"],
    RacineThemeColors.dataviz.map["9"],
    RacineThemeColors.dataviz.map["17"],
    RacineThemeColors.dataviz.map["20"],
    RacineThemeColors.dataviz.map["19"],
  ];

  type ThemeKeys = "COMPARE" | "CONTRAST" | "EXTENDED" | "DATAVIZROTATION";

  const THEME_MAP: Record<ThemeKeys, any> = {
    COMPARE: COMPARE_THEME,
    CONTRAST: CONTRAST_THEME,
    EXTENDED: EXTENDED_THEME,
    DATAVIZROTATION: RacineThemeColors.dataviz.list,
  };

  return THEME_MAP[theme.toUpperCase() as ThemeKeys][index];
};

export const useChartLabels = (
  legendLabels: TypeLegendLabels[],
  theme: TypeChartLegendTheme
) => {
  const racineTheme = useTheme();
  const colors = racineTheme.colors;

  return legendLabels.map((label, i) => {
    const labelColor = label.color
      ? label.color
      : getSwatchColor(theme, i, colors);

    return (
      <Label key={`chart-legend-label-${i}`}>
        <Swatch bg={labelColor} data-qa-swatch="" />
        <Text as="div" fontSize={200} breakWord>
          {label.name}
        </Text>
      </Label>
    );
  });
};
