import * as React from "react";
// @ts-expect-error lru-memoize is not typed
import memoize from "lru-memoize";
import { EM_DASH } from "./constants";
import { VisuallyHidden } from "@sproutsocial/seeds-react-visually-hidden";

import {
  COMPARE_OBJECTS,
  DEFAULT_DISPLAY,
  DEFAULT_DISPLAY_UNITS,
  DEFAULT_LOCALE,
  DEFAULT_MILLISECONDS,
  MEMO_CACHE_SIZE,
  ORDERED_UNITS,
  UNITS,
} from "./constants";
import { Container } from "./styles";
import type {
  TypeDurationProps,
  TypeDurationLocale,
  TypeDurationDisplay,
  TypeDurationDisplayUnits,
} from "./DurationTypes";
import {
  isValidNumber,
  getLowestUnit,
  splitMillisecondsIntoUnits,
} from "./utils";

const _createDurationFormatter = (
  locale: TypeDurationLocale,
  unitDisplay: TypeDurationDisplay,
  displayUnits: TypeDurationDisplayUnits
) => {
  const timeUnitFormatter = (
    locale: TypeDurationProps["locale"],
    unit: string,
    unitDisplay: TypeDurationProps["display"]
  ) => Intl.NumberFormat(locale, { style: "unit", unit, unitDisplay }).format;

  const formatterByUnit = {
    [UNITS.days]: timeUnitFormatter(locale, "day", unitDisplay),
    [UNITS.hours]: timeUnitFormatter(locale, "hour", unitDisplay),
    [UNITS.minutes]: timeUnitFormatter(locale, "minute", unitDisplay),
    [UNITS.seconds]: timeUnitFormatter(locale, "second", unitDisplay),
    [UNITS.milliseconds]: timeUnitFormatter(locale, "millisecond", unitDisplay),
  };

  const formatList = new Intl.ListFormat(locale, {
    style: "narrow",
    type: "unit",
  });

  return (value: number) => {
    const lowestUnit = getLowestUnit(displayUnits);

    // if the value is zero or negative, we just want to return 0 for the lowest unit (ex "0 minutes")
    if (value <= 0) {
      // @ts-ignore TS error later
      return formatterByUnit[lowestUnit](0);
    }

    const millisecondsByUnit = splitMillisecondsIntoUnits(value, displayUnits);
    const list: string[] = [];

    ORDERED_UNITS.forEach((unit) => {
      if (unit in millisecondsByUnit) {
        // @ts-ignore TS error later
        const unitValue = millisecondsByUnit[unit];

        // we want to add to the list if one of two conditions are met:
        // 1) the unit has a value greater than 0 OR
        // 2) the unit has value 0 AND the unit is the lowest unit AND the list is already empty
        if (
          unitValue !== 0 ||
          (unitValue === 0 && unit === lowestUnit && list.length === 0)
        ) {
          // @ts-ignore TS error later
          list.push(formatterByUnit[unit](millisecondsByUnit[unit]));
        }
      }
    });

    return formatList.format(list);
  };
};

// Memoize to reduce the energy of creating new instances of Intl.NumberFormat
const memoizer = memoize(MEMO_CACHE_SIZE, COMPARE_OBJECTS);
const createDurationFormatter = memoizer(_createDurationFormatter);

const getDuration = ({
  returnType,
  props,
}: {
  returnType: "string" | "component";
  props: TypeDurationProps;
}): string | React.ReactNode => {
  const {
    display = DEFAULT_DISPLAY,
    displayUnits = DEFAULT_DISPLAY_UNITS,
    invalidMillisecondsLabel,
    locale = DEFAULT_LOCALE,
    milliseconds = DEFAULT_MILLISECONDS,
    qa,
  } = props;
  const isReturnTypeString = returnType === "string";

  if (!isValidNumber(milliseconds)) {
    return isReturnTypeString ? (
      EM_DASH
    ) : (
      <>
        {invalidMillisecondsLabel ? (
          // Give screen readers something useful to read off + hide the em dash
          <VisuallyHidden>{invalidMillisecondsLabel}</VisuallyHidden>
        ) : null}
        <Container aria-hidden {...qa}>
          {EM_DASH}
        </Container>
      </>
    );
  }

  const validatedDisplayUnits =
    Object.keys(displayUnits).length === 0
      ? DEFAULT_DISPLAY_UNITS
      : displayUnits;

  const fullText = createDurationFormatter(
    locale,
    display,
    validatedDisplayUnits
  )(milliseconds);

  return isReturnTypeString ? (
    fullText
  ) : (
    <Container {...qa}>{fullText}</Container>
  );
};

export const formatDuration = (props: TypeDurationProps): string => {
  return getDuration({ returnType: "string", props }) as string;
};

const Duration = (props: TypeDurationProps) => {
  return getDuration({ returnType: "component", props });
};

export default Duration;
