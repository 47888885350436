'use strict';

export const TYPOGRAPHY_FAMILY = 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
export const TYPOGRAPHY_FAMILY_PROXIMA = '"Proxima Nova", proxima-nova, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
export const TYPOGRAPHY_FAMILY_RECOLETA = '"Recoleta", recoleta, proxima-nova, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
export const TYPOGRAPHY_FAMILY_ROBOTO = '"Roboto Serif", roboto-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
export const TYPOGRAPHY_SIZE_100 = {
  fontSize: '11px',
  lineHeight: '18.666666666666668px'
};
export const TYPOGRAPHY_SIZE_200 = {
  fontSize: '13px',
  lineHeight: '21.333333333333332px'
};
export const TYPOGRAPHY_SIZE_300 = {
  fontSize: '16px',
  lineHeight: '24px'
};
export const TYPOGRAPHY_SIZE_400 = {
  fontSize: '18px',
  lineHeight: '26.666666666666668px'
};
export const TYPOGRAPHY_SIZE_500 = {
  fontSize: '21px',
  lineHeight: '29.333333333333332px'
};
export const TYPOGRAPHY_SIZE_600 = {
  fontSize: '24px',
  lineHeight: '32px'
};
export const TYPOGRAPHY_SIZE_700 = {
  fontSize: '32px',
  lineHeight: '40px'
};
export const TYPOGRAPHY_SIZE_800 = {
  fontSize: '43px',
  lineHeight: '50.666666666666664px'
};
export const TYPOGRAPHY_SIZE_825 = {
  fontSize: '48px',
  lineHeight: '56px'
};
export const TYPOGRAPHY_SIZE_850 = {
  fontSize: '51px',
  lineHeight: '58.666666666666664px'
};
export const TYPOGRAPHY_SIZE_900 = {
  fontSize: '57px',
  lineHeight: '64px'
};
export const TYPOGRAPHY_SIZE_1000 = {
  fontSize: '76px',
  lineHeight: '80px'
};
export const TYPOGRAPHY_SIZE_1100 = {
  fontSize: '101px',
  lineHeight: '101.33333333333333px'
};
export const TYPOGRAPHY_SIZE_1200 = {
  fontSize: '135px',
  lineHeight: '125.33333333333333px'
};
export const TYPOGRAPHY_WEIGHT_NORMAL = '400';
export const TYPOGRAPHY_WEIGHT_SEMIBOLD = '600';
export const TYPOGRAPHY_WEIGHT_BOLD = '700';
export const TYPOGRAPHY_WEIGHT_EXTRA_BOLD = '800';


const defaultExport = {
  TYPOGRAPHY_FAMILY,
  TYPOGRAPHY_FAMILY_PROXIMA,
  TYPOGRAPHY_FAMILY_RECOLETA,
  TYPOGRAPHY_FAMILY_ROBOTO,
  TYPOGRAPHY_SIZE_100,
  TYPOGRAPHY_SIZE_200,
  TYPOGRAPHY_SIZE_300,
  TYPOGRAPHY_SIZE_400,
  TYPOGRAPHY_SIZE_500,
  TYPOGRAPHY_SIZE_600,
  TYPOGRAPHY_SIZE_700,
  TYPOGRAPHY_SIZE_800,
  TYPOGRAPHY_SIZE_825,
  TYPOGRAPHY_SIZE_850,
  TYPOGRAPHY_SIZE_900,
  TYPOGRAPHY_SIZE_1000,
  TYPOGRAPHY_SIZE_1100,
  TYPOGRAPHY_SIZE_1200,
  TYPOGRAPHY_WEIGHT_NORMAL,
  TYPOGRAPHY_WEIGHT_SEMIBOLD,
  TYPOGRAPHY_WEIGHT_BOLD,
  TYPOGRAPHY_WEIGHT_EXTRA_BOLD
};

export default defaultExport;