import * as React from "react";
import styled from "styled-components";
import Container from "./styles";
import type { TypeTextProps } from "./TextTypes";

const Headline = styled(Container)`
  color: ${(props) => props.theme.colors.text.headline};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  ${(props) => props.theme.typography[400]}
`;

const SubHeadline = styled(Container)`
  color: ${(props) => props.theme.colors.text.headline};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  ${(props) => props.theme.typography[300]}
`;

const SmallSubHeadline = styled(Container)`
  color: ${(props) => props.theme.colors.text.headline};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  ${(props) => props.theme.typography[200]}
`;

const Byline = styled(Container)`
  color: ${(props) => props.theme.colors.text.subtext};
  ${(props) => props.theme.typography[200]}
`;

const SmallByline = styled(Container)`
  color: ${(props) => props.theme.colors.text.subtext};
  ${(props) => props.theme.typography[100]}
`;

const BodyCopy = styled(Container)`
  color: ${(props) => props.theme.colors.text.body};
  ${(props) => props.theme.typography[300]}
`;

const SmallBodyCopy = styled(Container)`
  color: ${(props) => props.theme.colors.text.body};
  ${(props) => props.theme.typography[200]}
`;

const Text = ({ fontSize, children, qa, color, ...rest }: TypeTextProps) => {
  const qaText = typeof children === "string" ? children : undefined;

  return (
    <Container
      typeScale={fontSize}
      data-qa-text={qaText}
      // TODO: fix this type since `color` should be valid here. TS can't resolve the correct type.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      color={color}
      {...qa}
      {...rest}
    >
      {children}
    </Container>
  );
};

const withTextLogic = (
  Component: React.ComponentType<TypeTextProps>
): React.FC<TypeTextProps> => {
  return ({ children, ...props }: TypeTextProps) => {
    const qaText = typeof children === "string" ? children : undefined;
    return (
      <Component data-qa-text={qaText} {...props}>
        {children}
      </Component>
    );
  };
};

const HeadlineWithLogic = withTextLogic(Headline);
HeadlineWithLogic.displayName = "Text.Headline";
Text.Headline = HeadlineWithLogic;

const SubHeadlineWithLogic = withTextLogic(SubHeadline);
SubHeadlineWithLogic.displayName = "Text.SubHeadline";
Text.SubHeadline = SubHeadlineWithLogic;

const SmallSubHeadlineWithLogic = withTextLogic(SmallSubHeadline);
SmallSubHeadlineWithLogic.displayName = "Text.SmallSubHeadline";
Text.SmallSubHeadline = SmallSubHeadlineWithLogic;

const BylineWithLogic = withTextLogic(Byline);
BylineWithLogic.displayName = "Text.Byline";
Text.Byline = BylineWithLogic;

const SmallBylineWithLogic = withTextLogic(SmallByline);
SmallBylineWithLogic.displayName = "Text.SmallByline";
Text.SmallByline = SmallBylineWithLogic;

const BodyCopyWithLogic = withTextLogic(BodyCopy);
BodyCopyWithLogic.displayName = "Text.BodyCopy";
Text.BodyCopy = BodyCopyWithLogic;

const SmallBodyCopyWithLogic = withTextLogic(SmallBodyCopy);
SmallBodyCopyWithLogic.displayName = "Text.SmallBodyCopy";
Text.SmallBodyCopy = SmallBodyCopyWithLogic;

export default Text;
