  const MOTION_EASE_IN = 'cubic-bezier(.4, 0, .7, .2)';
  const MOTION_EASE_OUT = 'cubic-bezier(0, 0, .2, 1)';
  const MOTION_EASE_INOUT = 'cubic-bezier(.4, 0, .2, 1)';
  const MOTION_DURATION_FAST = 0.15;
  const MOTION_DURATION_MEDIUM = 0.3;
  const MOTION_DURATION_SLOW = 0.6;

export {
  MOTION_EASE_IN,
  MOTION_EASE_OUT,
  MOTION_EASE_INOUT,
  MOTION_DURATION_FAST,
  MOTION_DURATION_MEDIUM,
  MOTION_DURATION_SLOW
};

export default {
  MOTION_EASE_IN,
  MOTION_EASE_OUT,
  MOTION_EASE_INOUT,
  MOTION_DURATION_FAST,
  MOTION_DURATION_MEDIUM,
  MOTION_DURATION_SLOW
};