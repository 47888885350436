import * as React from "react";
import styled from "styled-components";
import Box from "@sproutsocial/seeds-react-box";
import Button from "@sproutsocial/seeds-react-button";
import Loader from "@sproutsocial/seeds-react-loader";
import type { TypeLoaderButtonProps } from "./LoaderButtonTypes";

const StyledLoaderButton = styled(Button)`
  background-color: ${({ theme, appearance, disabled }) => {
    const buttonBackgroundColor =
      theme.colors.button[appearance as keyof typeof theme.colors.button]
        ?.background?.base;

    const isBackgroundColorTransparent =
      buttonBackgroundColor === "transparent";

    // If the button is disabled we want to check what the buttonBackgroundColor is, if it is transparent we want to return transparent, otherwise we want to return the buttonBackgroundColor with an opacity of 0.4. If the button is not disabled we want to return the buttonBackgroundColor.
    return disabled
      ? isBackgroundColorTransparent
        ? "transparent"
        : `${buttonBackgroundColor}66`
      : buttonBackgroundColor;
  }};
  ${({ disabled }) => disabled && "opacity: 1;"}
`;

export const LoaderButton = ({
  isLoading = false,
  disabled,
  loaderLabel,
  children,
  appearance = "unstyled",
  ...rest
}: TypeLoaderButtonProps) => {
  return (
    <StyledLoaderButton
      disabled={disabled || isLoading}
      appearance={appearance}
      data-qa-button-isloading={isLoading === true}
      {...rest}
    >
      <Box position="relative">
        <Box opacity={isLoading ? 0 : 1} aria-hidden={isLoading}>
          {children}
        </Box>
        {isLoading && (
          <Box position="absolute" top={-2} left={0} right={0} bottom={0}>
            <Loader text={loaderLabel} size="small" delay={false} />
          </Box>
        )}
      </Box>
    </StyledLoaderButton>
  );
};

export default LoaderButton;
