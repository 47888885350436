import React, { useRef } from "react";
import { Tooltip, Text, Box, Icon } from "@sproutsocial/racine";
import { Container } from "./styles";
import { useWidth } from "./utils";
import { capitalize } from "utils/js";
import useComponentProps from "queries/useComponentProps";
import ClientOnly from "components/ClientOnly";

const getType = (type) => {
  if (type.elements) {
    const filteredElements = type.elements.filter(
      (element) => element.name !== "unknown"
    );
    return filteredElements.map((element) => element.value || element.name);
  }

  return [type.name];
};

const PropTag = ({ name, component }) => {
  const propContainer = useRef();
  const width = useWidth(propContainer);

  const componentProps = useComponentProps(component);
  const prop = componentProps.find((prop) => prop.name === name);

  const propData = {
    default: prop.defaultValue ? prop.defaultValue.value : null,
    required: prop.required,
    type: getType(prop.type),
    description: prop.description,
  };

  return (
    <Tooltip
      width="384px"
      content={
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            alignItems="center"
            mb={350}
            mt={-300}
            borderBottom={500}
            borderColor="container.border.base"
            mx={-400}
            px={400}
            pb={300}
          >
            <Icon mr={300} name="atom-outline" />
            <Text fontWeight="semibold">
              {capitalize(component.toLowerCase())}
            </Text>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={350}>
            <Box display="flex" flexDirection="column">
              <Text color="text.headline" fontWeight="bold">
                Prop
              </Text>
              <Text color="text.body" fontFamily="mono">
                {name}
              </Text>
            </Box>
            <Box display="flex" flexDirection="column">
              <Text color="text.headline" fontWeight="bold">
                Default
              </Text>
              <Text color="text.body" fontFamily="mono">
                {propData.default}
              </Text>
            </Box>
            <Box>
              <Text color="text.headline" fontWeight="bold">
                Required
              </Text>
              <Box>
                <Icon
                  size="mini"
                  name={propData.required ? "check-solid" : "x-solid"}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Text color="text.headline" fontWeight="bold">
              Type
            </Text>
            <Box display="flex">
              <Text color="text.body" fontFamily="mono">
                {propData.type.join(", ")}
              </Text>
            </Box>
          </Box>
          {propData.description && (
            <Box display="flex" flexDirection="column" mt={350}>
              <Text color="text.headline" fontWeight="bold">
                Description
              </Text>
              <Text color="text.body" fontSize={200}>
                {propData.description}
              </Text>
            </Box>
          )}
        </Box>
      }
    >
      <Container
        copy={name}
        success={
          <Box display="flex" alignItems="center" minWidth={width}>
            <Icon mr={300} size="mini" name="code-solid" />
            <Text color="text.body">Copied!</Text>
          </Box>
        }
      >
        <Box display="flex" alignItems="center" ref={propContainer}>
          <Icon mr={300} size="mini" name="code-solid" />
          <Text color="text.body">{name}</Text>
        </Box>
      </Container>
    </Tooltip>
  );
};

export default (props) => (
  <ClientOnly>
    <PropTag {...props} />
  </ClientOnly>
);
