export const COMPARE_OBJECTS = true;
export const MEMO_CACHE_SIZE = 10;

export const UNITS = {
  days: "days",
  hours: "hours",
  minutes: "minutes",
  seconds: "seconds",
  milliseconds: "milliseconds",
};

export const MILLISECONDS_IN = {
  [UNITS.days]: 1 * 1000 * 60 * 60 * 24,
  [UNITS.hours]: 1 * 1000 * 60 * 60,
  [UNITS.minutes]: 1 * 1000 * 60,
  [UNITS.seconds]: 1 * 1000,
  [UNITS.milliseconds]: 1,
};

export const ORDERED_UNITS = [
  UNITS.days,
  UNITS.hours,
  UNITS.minutes,
  UNITS.seconds,
  UNITS.milliseconds,
];

// Duration props defaults
export const DEFAULT_DISPLAY = "narrow";
export const DEFAULT_DISPLAY_UNITS = {
  [UNITS.days]: true,
  [UNITS.hours]: true,
  [UNITS.minutes]: true,
  [UNITS.seconds]: true,
  [UNITS.milliseconds]: false,
};

export const DEFAULT_LOCALE = "en-US";
export const DEFAULT_MILLISECONDS = null;

export const EM_DASH = "—"; // shift + option + hyphen on a mac keyboard
